@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: #000;
}

:root {
    --swiper-pagination-color: #FF4713 !important;
    --swiper-pagination-bullet-inactive-color: #FF4713;
}

.outlined-text {
    -webkit-text-stroke: 1px #FF4713;
    color: transparent;
    font-size: 3rem;
}

.outlined-text-grey {
    -webkit-text-stroke: 1px #9A9A9A;
    color: transparent;
    font-size: 3rem;
}

@keyframes radialAnimation {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.element {
    width: 200px;
    height: 200px;
    position: absolute;
    border-width: 1px;
    background-color: #FF4713;
    border-radius: 50%;
    animation: radialAnimation 2s infinite;
}


textarea:focus,
input:focus {
    outline: none;
}

.blog-detail ol,
.blog-detail ul,
.blog-detail menu {
    list-style: inside !important;
    margin: unset !important;
    padding: unset !important;
}

/* play button */
.play-container {
    position: relative;
  }
  
  .play-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    cursor: pointer;
    font-weight: bold;
    color: #fff;
    background-color: #FF4713;
    overflow: hidden;
    z-index: 2; /* play button'u en üst sıraya getirin */
  }
  
  .pulse-ring {
    position: absolute;
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center;
    border: 4px solid #FF4713;
    border-radius: 50%;
    width: 300px;
    height: 300px;
    animation: pulse 2s infinite;
    z-index: 1; /* pulse ring'i bir alt sıraya getirin */
  }
  
  @keyframes pulse {
    0% {
      transform: scale(0);
      opacity: 0.7;
    }
    100% {
      transform: scale(0.9);
      opacity: 0;
    }
  }
/* play button */

/* SwiperJS Next-Prev Button Position */
.swiper-button-next {
  z-index: 1;
  margin-left: 5px;   
}

.swiper-button-prev {
  z-index: 1;
  margin-right: 5px;
}

.swiper-slide img {
  max-width: 100%;
  height: auto;
}

.services .slick-slide{
  /* margin-right: 15px; */
  /* margin-left: 15px; */
}

.services .slick-dots li button:before{
  opacity: 1;
  color: white;
}

.services .slick-dots li.slick-active button:before{
  opacity: 1;
  color: #FF4713;
}
.elips-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .elips {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background: radial-gradient(circle at center, rgba(255, 71, 19, 0.7), rgba(255, 71, 19, 1));
    filter: blur(100px); /* Blur efekti ekleyin */
    opacity: 0.4;
  }
  
  @media screen and (max-width: 768px) {
    .elips {
      width: 200px;
      
    }
    
  }